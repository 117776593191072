html,
body {
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.app-bar {
  height: auto;
}

.app-bar-container {
  max-height: 108px;
  height: auto;

  &.padding {
    padding: 0 12px !important;
  }

  &.padding-xs {
    padding: 0 12px !important;
  }
}

.mat-tooltip {
  word-break: break-all !important;
  white-space: normal !important;
}
